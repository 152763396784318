import { useEffect, useState } from "react";
import { Form, Modal, ProgressBar, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  decodeString,
  getLocalStorage,
  removeLocalStorage,
} from "../utilities";
import { useTaxStatus } from "../../providers/TaxStatusProvider";
import "./styleForCommonComponent.css";
import {
  GetMandateListObj,
  GetStatusLogsResListObj,
  ReqUnitsOBJ,
  VcipDetailsType,
} from "../../services/reporting.service";
import {
  EMandateTermsModalProps,
  ExistingKycModalProps,
  ModalTypes,
  PopupModalCamsProps,
  PopupModalTypes,
  ProceedModalProps,
  ProductFeatureModalProps,
  ViewImageProps,
} from "../../types/common";
import { Button } from "../Button/Button";
import { PDFViewer } from "./pdfViewer";

export const initialModalValue = {
  modal: false,
  title: "",
  bodyMessage: "",
  isOk: "",
  isCancel: "",
  queryId: "",
  isLoading: false,
  submitFunction: () => { },
} as ModalTypes;

export const initialOncancelUpdateReq = {
  modal: false,
  isOk: "",
  isCancel: "",
  isLoading: false,
  cancelBtn: () => { },
  submitFunction: () => { },
};
export const initialModalValueCamsKfin = {
  modal: false,
  title: "",
  type: "",
  isOk: "",
  isCancel: "",
  isLoading: false,
  submitFunction: () => { },
};

export const productFeatureModalInitialVal = {
  modal: false,
  title: "",
  isOk: "",
  isCancel: "",
  isLoading: false,
  bodyMessage: "",
  cancelBtn: () => { },
  submitFunction: () => { },
};

export const initialModalValuePaymentSummary = {
  modal: false,
  title: "",
  bodyMessage: "",
  type: "",
  isOk: "",
  isCancel: "",
  isLoading: false,
  submitFunction: () => { },
  cancelFunction: () => { },
};

//common popup modal for entire project
export const PopupModal = ({
  modalData,
  setModalData,
  isConfirmModal,
}: PopupModalTypes) => {
  return (
    <Modal
      show={modalData.modal}
      backdrop="static"
      keyboard={modalData.isCancel ? true : false}
      onHide={() => setModalData(initialModalValue)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {modalData.title.length === 57 ? (
        <p className="d-flex commonModalTitle">{modalData.title}</p>
      ) : (
        <Modal.Header>
          <Modal.Title>{modalData.title}</Modal.Title>
        </Modal.Header>
      )}
      {modalData.queryId ? (
        <Modal.Body>
          <span style={{ fontWeight: 600 }}>{modalData.bodyMessage}</span>
          <span style={{ opacity: 0.7 }}>
            &nbsp; {`(Query Id: ${modalData?.queryId})`}
          </span>
        </Modal.Body>
      ) : (
        modalData.bodyMessage && (
       // CHANGES TAKEN TO VIEW PROPER HTML ELEMENT IN MODAL BODY  : REQUEST 73303 (DONE BY GAURAV SHARMA --> gaurav38)
          // <Modal.Body>{modalData.bodyMessage}</Modal.Body>
          <Modal.Body>
            <p dangerouslySetInnerHTML={{ __html: modalData.bodyMessage }} />
          </Modal.Body>
        )
      )}
      <Modal.Footer>
        {modalData.isCancel && (
          <Button
            onClick={() => setModalData(initialModalValue)}
            variant="secondary"
            name={modalData.isCancel}
            className="resendButton mt-0"
          />
        )}
        {modalData.isOk && (
          <Button
            onClick={modalData.submitFunction}
            isDisabled={modalData.isLoading || isConfirmModal}
            isLoading={modalData.isLoading || isConfirmModal}
            name={modalData.isOk}
            className="sendButton mt-0"
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const PopupModalCamsKfin = ({
  modalData,
  setModalData,
}: PopupModalCamsProps) => {
  return (
    <Modal
      show={modalData.modal}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={modalData.isCancel ? true : false}
      onHide={() => setModalData(initialModalValueCamsKfin)}
      centered
    >
      <Modal.Header>
        <Modal.Title>{modalData.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalData?.type === "cams" && (
          <div>
            <span className="font-700">
              Your pledged request with CAMS has failed. Possible reasons could
              be,
            </span>
            <ul className="mt-3">
              <li className="list-style-decimal">
                Incorrect Email/Mobile entered for CAMS
              </li>
              <li className="list-style-decimal">
                Some units/Folios may already be pledged
              </li>
              <li className="list-style-decimal">
                Securities already redeemed or redemption order is placed Please
                modify your security selection and resubmit the pledge request.
              </li>
            </ul>
          </div>
        )}
        {modalData?.type === "kfin" && (
          <div>
            <span className="font-700">
              Your pledged request with KFIN has failed. Possible reasons could
              be,
            </span>
            <ul className="mt-3">
              <li className="list-style-decimal">
                Incorrect Email/Mobile entered for KFIN
              </li>
              <li className="list-style-decimal">
                Some units/Folios may already be pledged
              </li>
              <li className="list-style-decimal">
                Securities already redeemed or redemption order is placed Please
                modify your security selection and resubmit the pledge request.
              </li>
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {modalData.isCancel && (
          <Button
            onClick={() => setModalData(initialModalValueCamsKfin)}
            variant="secondary"
            name={modalData.isCancel}
            className="resendButton"
          />
        )}
        {modalData.isOk && (
          <Button
            onClick={modalData.submitFunction}
            isDisabled={modalData.isLoading}
            name={modalData.isOk}
            isLoading={modalData.isLoading}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const ProceedModal = ({
  proceedModal,
  valueOfProcess,
  setProceedModal,
  closebtn,
  setValueOfProcess,
}: ProceedModalProps) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={proceedModal}
      className="model-Spinner"
      keyboard={false}
      centered
    >
      <>
        <div style={{ borderTop: "none" }} className="text-center">
          <span className="text-center">
            <p className="font-20">
              Please wait for <strong className="red">5 min</strong>{" "}
            </p>
          </span>
          <ProgressBar
            animated
            now={valueOfProcess}
            className="w-100"
            style={{ marginTop: "20px" }}
            max={300}
          />
          {closebtn ? (
            <div
              className="btn-close"
              onClick={() => {
                setProceedModal(false);
                setValueOfProcess(0);
              }}
            ></div>
          ) : null}
        </div>
      </>
    </Modal>
  );
};
export const ViewImage = ({
  imgBasePath,
  modalShow,
  setModalShow,
  title,
}: ViewImageProps) => {
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      keyboard={true}
      size={imgBasePath?.extension === "pdf" ? "lg" : "xl"}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ maxWidth: "none !important ", zIndex: 9999 }}
      centered
    >
      <Modal.Header closeButton>{title && <h4>{title}</h4>}</Modal.Header>
      <Modal.Body
        className="imageViewModel"
        style={{ height: "600px", overflow: "scroll", padding: "0" }}
      >
        {imgBasePath?.extension === "pdf" ? (
          <PDFViewer pdf={imgBasePath?.base64} />
        ) : (
          <img
            src={`data:image/${imgBasePath?.extension};base64,${imgBasePath?.base64}`}
            className="img-fluid viewUploadImage"
            draggable={false}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export const ExistingKycModal = ({
  modalShow,
  setModalShow,
  fileURL,
  fileData,
}: ExistingKycModalProps) => {
  return (
    <Modal
      show={modalShow}
      keyboard={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ maxWidth: "none !important " }}
      onHide={() => setModalShow(false)}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        className="imageViewModel"
        style={{
          height: "600px",
          overflow: "scroll",
          padding: "0",
          position: "relative",
        }}
      >
        {fileData && fileData?.type === "application/pdf" ? (
          <PDFViewer isFileURL pdf={fileURL} />
        ) : (
          <img
            src={fileURL}
            alt=""
            className="img-fluid viewUploadImage"
            draggable={false}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
export const EMandateTermsModal = ({
  modalData,
  setModalData,
}: EMandateTermsModalProps) => {
  return (
    <Modal
      show={modalData}
      keyboard={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ maxWidth: "none !important ", zIndex: 9999 }}
      centered
    >
      <Modal.Body className="imageViewModel">
        <div>
          <ul className="mt-3">
            <li className="list-style-decimal">
              Bank Mandate shall be in favor of 'NJ Capital Private Limited'.
              (Hereinafter 'NJC')
            </li>
            <li className="list-style-decimal">
              The Bank details in the Bank Mandate MUST match with bank account
              registered by the client with NJC.
            </li>
            <li className="list-style-decimal">
              In case of physical mandate(s) client signature on bank mandate
              must be as per bank records. Signature of all bank account holders
              required if mode of holding in account is “Joint”. Please note
              that physical mandate(s) take longer to register and also carry
              additional risk of getting rejected due to signature mismatch(s).
              We, hence recommend the registration of the bank mandate(s) by way
              of e-mandate or Aadhaar based mandate.
            </li>
            <li className="list-style-decimal">
              In case you choose to register by way of Aadhaar based e-mandate,
              kindly ensure that your bank account is Aadhaar linked so as to
              avoid rejection.
            </li>
            <li className="list-style-decimal">
              NJC reserves the right to register bank mandate in any available
              modes ie Auto Debit, ACH, ECS or any such mode.
            </li>
            <li className="list-style-decimal">
              Bank Mandate shall take atleast 15 days to get registered from the
              date of submission of Mandate at NJ PSC (subject to Bank
              confirmation). In case any payments fall due during this period,
              the Client shall make due arrangements to service the debt.
            </li>
            <li className="list-style-decimal">
              Client bank may charge client account for activating any such
              services. Neither NJC nor its service provider shall be held
              responsible for or bear any such charges.
            </li>
            <li className="list-style-decimal">
              Mandate format is subject to change as per the guidelines received
              from RBI/Bank/NPCI or other concerned governmental or statutory
              authorities. On receiving any such intimation from RBI or
              Bank/NPCI, NJC or its service provider may immediately request for
              change of the Mandate.
            </li>
            <li className="list-style-decimal">
              In case a registered bank mandate is required to be changed for
              any reason, the Client shall be responsible for making any interim
              payments by, which may fall due till the registration of the
              changed mandate, by any other approved modes.
            </li>
            <li className="list-style-decimal">
              The mandate can also be used for collection of all other
              applicable charges under the Loan Agreement including additional
              interest, processing charges, documentation charges, bounce
              charges, insurance charges, RTA charges for Pledge or removal of
              pledge etc. from the Client's bank account.
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModalData(false)} name="Ok" />
      </Modal.Footer>
    </Modal>
  );
};

export const ProductFeatureModal = ({
  modalData,
  setModalData,
}: ProductFeatureModalProps) => {
  return (
    <Modal
      show={modalData.modal}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={modalData.isCancel ? true : false}
      onHide={() => setModalData(productFeatureModalInitialVal)}
      centered
    >
      <Modal.Header>
        <Modal.Title>{modalData.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalData.bodyMessage}</Modal.Body>
      <Modal.Footer>
        {modalData.isCancel && (
          <Button
            onClick={modalData.cancelBtn}
            name={modalData.isCancel}
            className="resendButton"
            style={{ marginTop: "0px" }}
          />
        )}
        {modalData.isOk && (
          <Button
            onClick={modalData.submitFunction}
            name={modalData.isOk}
            isDisabled={modalData.isLoading}
            isLoading={modalData.isLoading}
            style={{ marginTop: "0px" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

type LoanReportModal = {
  show: boolean;
  setShow: (val: boolean) => void;
  loanStatusData: GetStatusLogsResListObj[] | null;
  pledgeReportTable: GetMandateListObj[] | null;
};

export const LoanReportsModal = ({
  show,
  setShow,
  loanStatusData,
  pledgeReportTable,
}: LoanReportModal) => {
  return (
    <Modal
      show={show}
      dialogClassName="modal-50w loanStatusModalResponsive"
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => setShow(false)}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Loan Status Change Log
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover style={{ margin: "auto" }} responsive>
          <thead>
            <tr>
              <th colSpan={3} className="text-center">
                Loan Status Report
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Status</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {loanStatusData &&
              loanStatusData?.map(
                (item: GetStatusLogsResListObj, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{item?.STATUS}</td>
                      <td>{item?.DATE}</td>
                      <td>{item?.DATEWITHTIME}</td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </Table>
        <Table
          striped
          bordered
          hover
          responsive
          style={{ margin: "auto", marginTop: "30px" }}
        >
          <thead>
            <tr>
              <th colSpan={6} className="text-center">
                Pledge Report
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Date of Pledge</th>
              <th>PRF No.</th>
              <th>Pledge Value(Rs.)</th>
              <th>Total ISIN</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {pledgeReportTable &&
              pledgeReportTable?.map((item: GetMandateListObj, i: number) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.ESIGN_DATE}</td>
                    <td>{item?.PRFNO}</td>
                    <td>{item?.VALUE}</td>
                    <td>{item?.TISIN}</td>
                    <td>{item?.STATUS}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export type UnpledgeTableReqProps = {
  show: boolean;
  setShow: (val: boolean) => void;
  unpledgeReqData: ReqUnitsOBJ[];
};
export const UnpledgeTableReqModal = ({
  show,
  setShow,
  unpledgeReqData,
}: UnpledgeTableReqProps) => {
  return (
    <Modal
      show={show}
      dialogClassName="modal-80w unpledgeModalResponsive"
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => setShow(false)}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Unpledge request details:{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          striped
          bordered
          hover
          responsive
          style={{ margin: "auto", marginTop: "30px", whiteSpace: "nowrap" }}
        >
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Unpledge Date</th>
              <th>URF No.</th>
              <th>Securities Name</th>
              <th>ISIN</th>
              <th>Folio No.</th>
              <th>Released Units</th>
              <th>LTV (%)</th>
              <th>NAV price</th>
              <th>Valuations (Rs.)</th>
              <th>Value (After Applying (LTV(%))(Rs.))</th>
              <th>Client ID </th>
              <th>PSN No</th>
              <th>Status</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {unpledgeReqData &&
              unpledgeReqData?.map((item: ReqUnitsOBJ, i: number) => {
                return (
                  <tr key={i}>
                    <td>{item?.SR_NO}</td>
                    <td>{item?.UNPLEDGE_DATE}</td>
                    <td>{item?.URF_NO}</td>
                    <td>{item?.SEC_NAME}</td>
                    <td>{item?.ISINNO}</td>
                    <td>{item?.FOLIO_NO}</td>
                    <td>{Number(item?.RELEASE_UNIT).toFixed(3)}</td>
                    <td>{item?.LTV}</td>
                    <td>{"₹ " + Number(item?.NAV).toFixed(2)}</td>
                    <td>{item?.VALUATION}</td>
                    <td>
                      {"₹ " +
                        (
                          Number(item?.LTV_VAL)
                        ).toFixed(2)}
                    </td>
                    <td>{item?.CLIENT_ID}</td>
                    <td>{item?.PSNNO}</td>
                    <td>{item?.STATUS}</td>
                    <td>{item?.REMARK}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export const CancelReasonModal = ({
  handleSubmit,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  modalData,
  setModalData,
}: any) => {
  return (
    <Modal
      show={modalData.modal}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ maxWidth: "none !important " }}
      keyboard={false}
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="font-600">Alert</Modal.Header>
        <Modal.Body
          className="imageViewModel"
          style={{
            padding: "0",
            position: "relative",
          }}
        >
          <div className="d-flex justify-content-between row">
            <div className="col-md-12 col-12">
              <Form.Group className="otp-section mx-3 otp-main-form">
                <h6 className="otp-lable otp-lable-new mb-3">
                  Please Enter Cancellation reason
                </h6>
                <Form.Control
                  className="otp-box otp-box-new"
                  type="text"
                  name="cancelReason"
                  value={values}
                  isInvalid={Boolean(
                    touched.cancelReason && errors.cancelReason
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoFocus
                />
                <Form.Text className="text-muted">
                  {touched.cancelReason && errors.cancelReason}
                </Form.Text>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            name="Submit"
            type="submit"
            className="sendButton right-ten mt-0"
          />
          <Button
            name="Cancel"
            className="sendButton mt-0"
            onClick={() => {
              setModalData(initialOncancelUpdateReq);
            }}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

//if not getting any activity user side then show logging out timer modal with timer
export const PromptModal = ({ screenIdeal }: any) => (
  <div className="promptModalBg">
    <div className="logOutInner">
      <h3 className="mb-0 pb-0" style={{ fontWeight: 700 }}>
        Are you still here?
      </h3>
      <p
        className="mb-0 pb-0"
        style={{ color: "#1268c0", fontWeight: 600, fontSize: "18px" }}
      >
        Logging out in{" "}
        <span style={{ color: "red", fontWeight: 700 }}>
          0:0
          <span style={{ color: "red", fontSize: "20px" }}>
            {screenIdeal.remaining}
          </span>
        </span>
        seconds
      </p>
      <Button
        name="Still Here"
        className="sendButton mt-0"
        onClick={() => {
          screenIdeal.handleStillHere();
        }}
      />
    </div>
  </div>
);

// if token expired then session was closed and open session closed modal for 5 seconds and redirect the login page.
export const SessionClosedModal = () => {
  const { showSessionClosed, setShowSessionClosed } = useTaxStatus();
  const navigate = useNavigate();
  const [logoutTimer, setLogoutTimer] = useState<number>(5);
  const module = getLocalStorage("module");
  const decodeModuleName = module && decodeString(module);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (showSessionClosed) {
        setLogoutTimer((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [showSessionClosed]);

  const logoutResponse = (path: string) => {
    setShowSessionClosed(false);
    navigate(path);
    window.location.reload();
  };

  useEffect(() => {
    if (logoutTimer === 0 && showSessionClosed) {
      if (decodeModuleName === "Customer") {
        logoutResponse("/partner/customer/login");
      } else if (decodeModuleName === "Partner") {
        logoutResponse("/partner/partner/login");
      } else if (decodeModuleName === "Partner_Enrolment") {
        logoutResponse("/partner/partner/draft/login");
      } else {
        removeLocalStorage("token");
        setShowSessionClosed(false);
        window.location.reload();
      }
    }
  }, [logoutTimer]);
  return (
    <div className="promptModalBg">
      <div className="logOutInnerBG">
        <h5 className="mb-0 pb-0" style={{ fontWeight: 700 }}>
          Your session has expired, Please login again to continue. You will be
          automatically logged out in{" "}
          <span style={{ color: "blue", fontSize: "20px" }}>{logoutTimer}</span>
        </h5>
      </div>
    </div>
  );
};

//    71596 changes regarding PPR done by GAURAV SHARMA(gaurav38)
export const PPRModal = (
  {
    isShowPPRModal,
    setIsShowPPRModal,
    // setRdImpactRepay,
    setFieldValue,
    handleSubmit,
  }: any) => {
  return (
    <Modal
      show={isShowPPRModal}
      dialogClassName="modal-80w unpledgeModalResponsive"
      aria-labelledby="example-custom-modal-styling-title"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <p>Maintaining the original loan tenure is one of the important eligibility conditions for receiving a prompt payment rebate.</p>
        <p>Would you still like to continue reducing the loan tenure?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          name="continue(you will lose the PPR amount)"
          className="sendButton right-ten mt-0"
          onClick={() => {
            handleSubmit();
          }}
        />
        <Button
          name="Reduce EMI amount instead"
          className="sendButton right-ten mt-0"
          onClick={() => {
            setFieldValue("repaymentScheduleImpactOption", "3");
            handleSubmit();
          }}
        />
        <Button
          name="Cancel"
          className="sendButton mt-0"
          onClick={() => {
            setIsShowPPRModal(false);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
export const PaymentSummaryModal = (
  {
    paymentModalData,
    setPaymentModalData,
  }: any) => {
  return (
    <Modal
      show={paymentModalData.modal}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      centered
    >
      {paymentModalData.title.length === 57 ? (
        <p className="d-flex commonModalTitle">{paymentModalData.title}</p>
      ) : (
        <Modal.Header>
          <Modal.Title>{paymentModalData.title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {paymentModalData.bodyMessage}
      </Modal.Body>
      <Modal.Footer>
        {paymentModalData.isCancel && (
          <Button
            onClick={() => {
              setPaymentModalData(initialModalValuePaymentSummary)
              paymentModalData.cancelFunction()
            }}
            variant="secondary"
            name={paymentModalData.isCancel}
            className="resendButton"
          />
        )}
        {paymentModalData.isOk && (
          <Button
            onClick={paymentModalData.submitFunction}
            isDisabled={paymentModalData.isLoading}
            name={paymentModalData.isOk}
            isLoading={paymentModalData.isLoading}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
//    71596 changes regarding PPR done by GAURAV SHARMA(gaurav38)

type LoanReportsVcipCompletedModal = {
  show: boolean;
  setShow: (val: boolean) => void;
  vcipDetailsData: VcipDetailsType[] | null;
};

export const LoanReportsVcipCompletedModal = ({
  show,
  setShow,
  vcipDetailsData,
}: LoanReportsVcipCompletedModal) => {
  return (
    <Modal
      show={show}
      dialogClassName="modal-50w loanStatusModalResponsive"
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => setShow(false)}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          V-CIP Completed Log
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover style={{ margin: "auto" }} responsive>
          <thead>
            <tr>
              <th>Applicant Name</th>
              <th>Reviewer Complete Date</th>
              <th>Reviewer Complete Time</th>
            </tr>
          </thead>
          <tbody>
            {vcipDetailsData &&
              vcipDetailsData?.map(
                (item: VcipDetailsType, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{item?.NAME}</td>
                      <td>{item?.VCIP_COMPLETED_DATE}</td>
                      <td>{item?.VCIP_COMPLETED_TIME}</td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};